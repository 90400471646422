import React from "react";
import {AxiosError} from "axios";
import toaster from "rsuite/toaster";
import Message from "rsuite/Message";

const duration = 4000;

function getErrorDetails(error: AxiosError) {
    const reason = error?.response?.data?.detail;

    if (!reason) {
        return "";
    }

    return ` (${JSON.stringify(reason)})`;
}

function getErrorStatus(error: AxiosError) {
    const status = error?.response?.status;
    return `Ошибка ${status}. `;
}

const Alert = {
    error: (error: string) => {
        toaster.push(
            <Message type="error" showIcon duration={duration}>
                {error}
            </Message>,
        );
    },
    errorWithCode: (error: AxiosError, message: string) => {
        toaster.push(
            <Message type="error" showIcon duration={duration}>
                {`${getErrorStatus(error)}${message}${getErrorDetails(error)}`}
            </Message>,
        );
    },
    success: (success: string) => {
        toaster.push(
            <Message type="success" showIcon duration={duration}>
                {success}
            </Message>,
        );
    },
};

export default Alert;
