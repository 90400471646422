import React from "react";
import {useThemeContext} from "src/components/Theme/ThemeContext";
import MoonIcon from "src/assets/icons/MoonIcon";
import SunIcon from "src/assets/icons/SunIcon";
import Logout from "src/components/Logout";
import useUser from "src/api/queries/user/useUser";
import useNavBarTitle from "src/components/NavBar/NavBarTitleContext";
import {
    NavBarWrapper,
    NavSpace,
    NavIcon,
    NavUser,
    NavTitle,
} from "./NavBar.styles";

const NavBar = () => {
    const {isDark, toggle} = useThemeContext();
    const {data} = useUser();
    const [title] = useNavBarTitle();

    return (
        <NavBarWrapper>
            <NavTitle>{title}</NavTitle>
            <NavSpace />
            {data && <NavUser>{data.name}</NavUser>}
            <NavIcon onClick={toggle}>
                {isDark ? <MoonIcon /> : <SunIcon />}
            </NavIcon>
            <Logout />
        </NavBarWrapper>
    );
};

export default NavBar;
