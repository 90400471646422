import React from "react";

const WorkersIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 19.263h4.71a1 1 0 0 0 .95-.688l3.927-11.98a.2.2 0 0 1 .382.003l6.173 19.879a.2.2 0 0 0 .375.018l2.79-6.62a1 1 0 0 1 .922-.612H29"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

export default WorkersIcon;
