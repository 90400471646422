import axios, {AxiosError} from "axios";
import {useMutation, useQueryClient} from "react-query";
import api from "src/api";
import {Queries} from "src/api/queries/Queries";
import Alert from "src/lib/alerts";

const logoutRequest = async () => {
    const {data} = await axios.post(api.auth.logout);
    return data;
};

const useUserLogout = () => {
    const queryClient = useQueryClient();

    return useMutation(logoutRequest, {
        onSuccess: () => {
            queryClient.setQueryData(Queries.user, undefined);
        },
        onError: (error: AxiosError) => {
            Alert.errorWithCode(error, "Не удалось выйти");
        },
    });
};

export default useUserLogout;
