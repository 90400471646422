import {createGlobalStyle, css} from "styled-components";

const global = css`
    :root {
        // colors

        --white: #ffffff;
        --light-gray: #f3f3f3;
        --light-gray-2: #e6e6e6;
        --gray: #5d5d68;
        --dark-gray: #1c1d1f;
        --dark: #141414;
        --charcoal-gray: #333333;
        --black: #222225;
        --green: #19861c;
        --green-lightened-10: #33a036;
        --green-lightened-20: #4cb94f;
        --green-lightened-30: #66d369;
        --green-lightened-40: #7fec82;
        --green-lightened-50: #98ff9b;
        --green-darkened-10: #006d03;
        --green-darkened-20: #005300;
        --green-darkened-30: #003a00;
        --green-darkened-40: #002000;
        --green-darkened-50: #000700;
        --semi-transparent-green: rgba(25, 134, 28, 0.2);
        --red: #d00000;
        --red-lightened-10: #ea1a1a;
        --red-lightened-20: #ff3333;
        --red-lightened-30: #ff4d4d;

        // variables

        --nav-height: 72px;
        --error-color: var(--red);
        --main-color: var(--green);
    }

    body,
    body.rs-theme-light,
    body.rs-theme-dark {
        background-color: ${(props) => props.theme.loaderBg};

        --rs-loader-backdrop: ${(props) => props.theme.loaderBg};
        --rs-list-bg: ${(props) => props.theme.listBg};
        --rs-loader-backdrop: ${(props) => props.theme.loaderBg};
        --rs-list-bg: ${(props) => props.theme.listBg};
        --rs-list-hover-bg: var(--semi-transparent-green);
        --rs-list-placeholder-border: var(--main-color);

        --rs-bg-card: ${(props) => props.theme.cardBg};
        --rs-state-hover-bg: var(--semi-transparent-green);

        --rs-toggle-checked-bg: var(--main-color);
        --rs-toggle-checked-hover-bg: var(--green-lightened-10);

        --rs-input-focus-border: var(--main-color);
        --rs-state-focus-shadow: none;
        --rs-picker-value: var(--white);

        // buttons start
        --rs-btn-primary-bg: var(--main-color);
        --rs-btn-primary-hover-bg: var(--green-lightened-10);
        --rs-btn-primary-active-bg: var(--green-lightened-10);

        --rs-btn-ghost-border: var(--main-color);
        --rs-btn-ghost-text: var(--main-color);
        --rs-btn-ghost-hover-border: var(--green-lightened-10);
        --rs-btn-ghost-hover-text: var(--green-lightened-10);
        --rs-btn-ghost-active-border: var(--green-lightened-10);
        --rs-btn-ghost-active-text: var(--green-lightened-10);

        --rs-btn-link-text: var(--green-lightened-10);
        --rs-btn-link-hover-text: var(--green-lightened-10);
        --rs-btn-link-active-text: var(--green-lightened-10);

        --rs-iconbtn-primary-addon: var(--green-lightened-10);
        --rs-iconbtn-primary-activated-addon: var(--green-lightened-10);
        --rs-iconbtn-primary-pressed-addon: var(--green-lightened-10);
        // buttons end

        --rs-bg-active: var(--main-color);
        --rs-input-bg: ${(props) => props.theme.inputBg};

        --rs-form-errormessage-bg: var(--error-color);
        --rs-form-errormessage-border: var(--error-color);
        --rs-form-errormessage-text: var(--white);

        --rs-bg-overlay: ${(props) => props.theme.cardBg};
        --rs-picker-value: ${(props) => props.theme.color};

        --rs-listbox-option-selected-bg: var(--main-color);
        --rs-listbox-option-hover-bg: var(--green-lightened-20);
        --rs-listbox-option-selected-text: var(--white);
        --rs-listbox-option-hover-text: var(--white);

        --rs-checkbox-checked-bg: var(--main-color);

        --rs-text-link: var(--main-color);
        --rs-text-link-hover: var(--green-lightened-10);
        --rs-text-link-active: var(--green-lightened-10);

        --rs-message-success-bg: var(--green-lightened-20);
        --rs-message-error-bg: var(--red-lightened-20);
        --rs-message-error-text: var(--white);
        --rs-message-error-icon: var(--white);
    }

    .rs-picker-default .rs-picker-toggle {
        border: 1px solid ${(props) => props.theme.inputBorderColor};
    }

    .rs-loader-wrapper {
        z-index: 10;
    }

    .rs-panel {
        background-color: ${(props) => props.theme.cardBg};
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    }

    .rs-modal-backdrop {
        background-color: rgba(0, 0, 0, 0.75);
    }

    .rs-modal-content {
        background-color: ${(props) => props.theme.modalBg};
    }

    .rs-modal-body {
        padding-bottom: 0;
        margin-bottom: 1.25rem;
    }

    .rs-table-header-row-wrapper {
        font-size: 1rem;
    }

    .rs-checkbox-wrapper .rs-checkbox-inner:before {
        background-color: ${(props) => props.theme.checkBoxBg};
    }

    * {
        scrollbar-color: ${(props) => props.theme.scrollbarThumbBg}
            ${(props) => props.theme.scrollbarTrackBg};
        scrollbar-width: thin;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.scrollbarTrackBg};
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.scrollbarThumbBg};
        border-radius: 20px;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: ${(props) => props.theme.scrollbarTrackBg};
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }

    .mb-1 {
        margin-bottom: 1rem;
    }
`;

const GlobalStyles = createGlobalStyle`
    ${global};
`;

export default GlobalStyles;
