import React from "react";

const ErrorsIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 8.438c0-.816.661-1.477 1.477-1.477H16L9.874 17.497A1 1 0 0 0 10.738 19h10.956v8.197a.37.37 0 0 1-.574.307L14.666 23.2a1.477 1.477 0 0 0-1.638 0l-6.454 4.303A.37.37 0 0 1 6 27.197V8.438Z"
            stroke="currentColor"
            strokeWidth={2}
        />
        <path
            d="m9.886 17.493 8.252-14.028a1 1 0 0 1 1.724 0l8.252 14.028A1 1 0 0 1 27.252 19H10.748a1 1 0 0 1-.862-1.507Z"
            stroke="currentColor"
            strokeWidth={2}
        />
        <path
            d="M19 9v3"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
        <circle cx={19} cy={15} r={1} fill="#fff" />
    </svg>
);

export default ErrorsIcon;
