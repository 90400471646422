import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const ColumnsPage = lazy(() => import("src/pages/columns/ColumnsPage"));

export interface IColumnsRoutes {
    columns: IRoute;
}

const url = `${ADMIN_PATH}/columns`;

const columnsRoutes: IColumnsRoutes = {
    columns: {
        title: "Цель",
        url: () => url,
        page: <ColumnsPage />,
    },
};

export default columnsRoutes;
