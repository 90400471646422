import React from "react";

const SourcesIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m4 24 2.323-10.222A1 1 0 0 1 7.298 13H26M4 24V9a1 1 0 0 1 1-1h12a1 1 0 0 0 1-1v0a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v0a1 1 0 0 0 1 1h1a1 1 0 0 1 1 1v4M4 24v0a1 1 0 0 0 1 1h20.22a1 1 0 0 0 .97-.758l2.5-10A1 1 0 0 0 27.72 13H26"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

export default SourcesIcon;
