import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const WorkersPage = lazy(() => import("src/pages/workers/WorkersPage"));

export interface IWorkersRoutes {
    workers: IRoute;
}

const url = `${ADMIN_PATH}/workers`;

const workersRoutes: IWorkersRoutes = {
    workers: {
        title: "Обработчики",
        url: () => url,
        page: <WorkersPage />,
    },
};

export default workersRoutes;
