import React, {FC} from "react";
import {IRoute} from "src/routes/IRoute";
import {SideBarItemWrapper, SideBarLinkWrapper} from "./SideBarItem.styles";

interface ISideBarItemProps {
    route: IRoute;
    icon: React.ReactNode;
}

const SideBarItem: FC<ISideBarItemProps> = React.memo(({route, icon}) => (
    <SideBarItemWrapper>
        <SideBarLinkWrapper to={route.url()}>
            {icon}
            <span>{route.title}</span>
        </SideBarLinkWrapper>
    </SideBarItemWrapper>
));

export default SideBarItem;
