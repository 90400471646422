import React from "react";

const SunIcon = () => (
    <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={9} cy={9} r={4} stroke="currentColor" strokeWidth={2} />
        <path
            d="M16 9h1M1 9h1M9 16v1M9 1v1M13.95 13.95l.707.707M3.343 3.343l.707.707M4.05 13.95l-.707.707M14.657 3.343l-.707.707"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

export default SunIcon;
