import {DefaultTheme} from "styled-components";

declare module "styled-components" {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    export interface DefaultTheme {
        navBar: string;
        sideBarBg: string;
        sideBarHeaderBg: string;
        sideBarItemColor: string;
        sideBarItemActiveColor: string;
        content: string;
        color: string;
        cardBg: string;
        loaderBg: string;
        listBg: string;
        paginationBtnActiveColor: string;
        paginationBtnActiveBg: string;
        paginationBtnActiveBorderColor: string;
        scrollbarTrackBg: string;
        scrollbarThumbBg: string;
        jsonEditorBg: string;
        modalBg: string;
        addButtonBg: string;
        inputBg: string;
        inputBorderColor: string;
        tabBg: string;
        tabBorderColor: string;
        codeEditorBg: string;
        codeEditorBorderColor: string;
        codeEditorColor: string;
        checkBoxBg: string;
    }
}

export const lightTheme: DefaultTheme = {
    navBar: "var(--white)",
    sideBarBg: "var(--dark-gray)",
    sideBarHeaderBg: "var(--black)",
    sideBarItemColor: "var(--white)",
    sideBarItemActiveColor: "var(--white)",
    content: "var(--light-gray)",
    color: "var(--gray)",
    cardBg: "var(--white)",
    loaderBg: "var(--light-gray)",
    listBg: "var(--white)",
    paginationBtnActiveColor: "var(--white)",
    paginationBtnActiveBg: "var(--main-color);",
    paginationBtnActiveBorderColor: "var(--main-color);",
    scrollbarTrackBg: "var(--white)",
    scrollbarThumbBg: "var(--main-color);",
    jsonEditorBg: "var(--white)",
    modalBg: "var(--white)",
    addButtonBg: "transparent",
    inputBg: "var(--white)",
    inputBorderColor: "var(--rs-border-primary)",
    tabBg: "var(--light-gray-2)",
    tabBorderColor: "var(--rs-border-primary)",
    codeEditorBg: "var(--white)",
    codeEditorBorderColor: "var(--rs-border-primary)",
    codeEditorColor: "var(--dark)",
    checkBoxBg: "var(--white)",
};

export const darkTheme: DefaultTheme = {
    navBar: "var(--dark-gray)",
    sideBarBg: "var(--dark-gray)",
    sideBarHeaderBg: "var(--black)",
    sideBarItemColor: "var(--white)",
    sideBarItemActiveColor: "var(--white)",
    content: "var(--dark)",
    color: "var(--white)",
    cardBg: "var(--dark-gray)",
    loaderBg: "var(--dark)",
    listBg: "var(--dark-gray)",
    paginationBtnActiveColor: "var(--white)",
    paginationBtnActiveBg: "var(--main-color);",
    paginationBtnActiveBorderColor: "var(--main-color);",
    scrollbarTrackBg: "var(--dark-gray)",
    scrollbarThumbBg: "var(--main-color);",
    jsonEditorBg: "var(--gray)",
    modalBg: "var(--dark-gray)",
    addButtonBg: "var(--charcoal-gray)",
    inputBg: "var(--charcoal-gray)",
    inputBorderColor: "transparent",
    tabBg: "var(--dark-gray)",
    tabBorderColor: "var(--gray)",
    codeEditorBg: "var(--dark)",
    codeEditorBorderColor: "transparent",
    codeEditorColor: "var(--white)",
    checkBoxBg: "transparent",
};
