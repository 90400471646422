import React from "react";

const LocksIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12 14.265h-1a2 2 0 0 0-2 2V24a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-7.735a2 2 0 0 0-2-2h-1m-8 0V10a4 4 0 0 1 8 0v4.265m-8 0h8"
            stroke="currentColor"
            strokeWidth={2}
        />
    </svg>
);

export default LocksIcon;
