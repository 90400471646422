import axios, {AxiosError} from "axios";
import {useQuery} from "react-query";
import Alert from "src/lib/alerts";
import api from "src/api";
import IUser from "src/interfaces/IUser";
import {Queries} from "src/api/queries/Queries";

const verifyAuthRequest = async () => {
    const {data} = await axios.get<IUser>(api.auth.verify);
    return data;
};

const useUser = () =>
    useQuery(Queries.user, verifyAuthRequest, {
        staleTime: 600000, // 10 min,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onError: (error: AxiosError) => {
            if (error?.response?.status !== 401) {
                Alert.errorWithCode(error, "Не удалось проверить авторизацию");
            }
        },
    });

export default useUser;
