import React, {FC, ReactNode} from "react";
import Loader from "rsuite/Loader";

interface ILoadingProps {
    content?: ReactNode;
    backdrop?: boolean;
}

const Loading: FC<ILoadingProps> = ({content, backdrop = true}) => {
    return (
        <Loader
            size="md"
            backdrop={backdrop}
            center
            vertical
            content={content}
        />
    );
};

export default Loading;
