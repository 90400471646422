import React, {FC} from "react";
import SideBar from "src/components/SideBar";
import NavBar from "src/components/NavBar";
import {NavBarTitleProvider} from "src/components/NavBar/NavBarTitleContext";
import {
    AdminLayoutWrapper as Wrapper,
    AdminLayoutContent as Content,
    AdminLayoutContainer as Container,
} from "./AdminLayout.styles";

const AdminLayout: FC = ({children}) => {
    return (
        <NavBarTitleProvider>
            <Wrapper>
                <SideBar />
                <Container>
                    <NavBar />
                    <Content>{children}</Content>
                </Container>
            </Wrapper>
        </NavBarTitleProvider>
    );
};

export default AdminLayout;
