import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const HistoricPage = lazy(() => import("src/pages/historic/HistoricPage"));

export interface IHistoricRoutes {
    historic: IRoute;
}

const url = `${ADMIN_PATH}/historic`;

const historicRoutes: IHistoricRoutes = {
    historic: {
        title: "История запусков",
        url: () => url,
        page: <HistoricPage />,
    },
};

export default historicRoutes;
