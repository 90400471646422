import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const LocksPage = lazy(() => import("src/pages/locks/LocksPage"));

export interface ILocksRoutes {
    locks: IRoute;
}

const url = `${ADMIN_PATH}/locks`;

const locksRoutes: ILocksRoutes = {
    locks: {
        title: "Блокировки",
        url: () => url,
        page: <LocksPage />,
    },
};

export default locksRoutes;
