import React from "react";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import "rsuite/dist/rsuite.min.css";
import AppRouter from "src/routes/AppRouter";
import {ThemeProvider} from "src/components/Theme/ThemeContext";
import GlobalStyles from "./styles/Global.styles";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            staleTime: 60000, // 1min
        },
    },
});

function App() {
    return (
        <ThemeProvider>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
                <GlobalStyles />
                <AppRouter />
            </QueryClientProvider>
        </ThemeProvider>
    );
}

export default App;
