import React from "react";
import {Sidenav, Nav} from "rsuite";
import routes from "src/routes";
import ColumnsIcon from "src/assets/icons/ColumnsIcon";
import ErrorsIcon from "src/assets/icons/ErrorsIcon";
import HistoricIcon from "src/assets/icons/HistoricIcon";
import LocksIcon from "src/assets/icons/LocksIcon";
import LogoIcon from "src/assets/icons/LogoIcon";
import SourcesIcon from "src/assets/icons/SourcesIcon";
import ManualsIcon from "src/assets/icons/ManualsIcon";
import WorkersIcon from "src/assets/icons/WorkersIcon";
import SideBarItem from "./SideBarItem";
import {SideBarItems, SideBarLogo, SideBarWrapper} from "./SideBar.styles";

const SideBar = () => (
    <SideBarWrapper>
        <Sidenav.Header>
            <SideBarLogo to={routes.main.url()}>
                <LogoIcon />
            </SideBarLogo>
        </Sidenav.Header>

        <SideBarItems>
            <Nav>
                <SideBarItem icon={<ColumnsIcon />} route={routes.columns} />
                <SideBarItem icon={<SourcesIcon />} route={routes.sources} />
                <SideBarItem icon={<ManualsIcon />} route={routes.manuals} />
                <SideBarItem icon={<WorkersIcon />} route={routes.workers} />
                <SideBarItem icon={<HistoricIcon />} route={routes.historic} />
                <SideBarItem icon={<ErrorsIcon />} route={routes.errors} />
                <SideBarItem icon={<LocksIcon />} route={routes.locks} />
            </Nav>
        </SideBarItems>
    </SideBarWrapper>
);

export default SideBar;
