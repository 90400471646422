import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const ManualsPage = lazy(() => import("src/pages/manuals/ManualsPage"));

export interface IManualsRoutes {
    manuals: IRoute;
}

const url = `${ADMIN_PATH}/manuals`;

const manualsRoutes: IManualsRoutes = {
    manuals: {
        title: "Ручные задачи",
        url: () => url,
        page: <ManualsPage />,
    },
};

export default manualsRoutes;
