import styled from "styled-components";

export const NavBarWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;

    height: var(--nav-height);
    width: 100%;

    padding: 0 2.5rem;

    background-color: ${(props) => props.theme.navBar};
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

    color: ${(props) => props.theme.color};
`;

export const NavTitle = styled.div`
    font-weight: 700;
    font-size: 1.25rem;
`;

export const NavSpace = styled.div`
    flex-grow: 1;
`;

export const NavUser = styled.div`
    margin: 0 1rem;
`;

export const NavGoToSite = styled.a`
    margin-right: 1rem;

    color: ${(props) => props.theme.color};
`;

export const NavIcon = styled.div`
    width: 1.25rem;
    height: 1.25rem;

    cursor: pointer;
    user-select: none;

    &:not(:last-child) {
        margin-right: 1rem;
    }
`;
