import styled from "styled-components";
import {Link} from "react-router-dom";
import {Sidenav} from "rsuite";
import Pattern from "src/assets/images/pattern.png";

export const SideBarWrapper = styled(Sidenav)`
    width: 18rem;
    height: 100%;

    background-color: ${(props) => props.theme.sideBarBg};
`;

export const SideBarItems = styled(Sidenav.Body)`
    position: relative;

    &:before {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        content: "";

        background: url(${Pattern});
        background-size: cover;

        opacity: 0.05;
    }

    width: 100%;
    height: 100%;
`;

export const SideBarLogo = styled(Link)`
    height: var(--nav-height);
    min-height: var(--nav-height);

    width: 100%;
    padding: 0 1.5rem;

    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;

    svg {
        width: 8rem;
        color: var(--white);
    }

    background-color: ${(props) => props.theme.sideBarHeaderBg};
    box-shadow: 5px 5px 22px -11px rgba(0, 0, 0, 0.5) inset;
`;
