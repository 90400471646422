import Id from "src/types/Id";

const api = {
    auth: {
        verify: "/api/current",
        login: "/api/login",
        logout: "/api/logout",
        oauth: "/api/oauth",
    },
    columns: {
        get: "/api/columns",
        create: "/api/columns",
        delete: (id: Id) => `/api/columns/${id}`,
        edit: (id: Id) => `/api/columns/${id}`,
        move: (id: Id) => `/api/columns/${id}/move`,
    },
    sources: {
        get: "/api/sources",
        create: "/api/sources",
        edit: (id: Id) => `/api/sources/${id}`,
        getMeta: "/api/sources/meta",
        getById: (id: Id) => `/api/sources/${id}`,
        enable: (id: Id) => `/api/sources/${id}/enable`,
        disable: (id: Id) => `/api/sources/${id}/disable`,
        delete: (id: Id) => `/api/sources/${id}`,
        getSourceColumns: (sourceId: Id) => `/api/sources/${sourceId}/columns`,
        getSourceColumnsMeta: (sourceId: Id) =>
            `/api/sources/${sourceId}/columns/meta`,
        createSourceColumn: (sourceId: Id) =>
            `/api/sources/${sourceId}/columns`,
        editSourceColumn: (sourceId: Id, columnId: Id) =>
            `/api/sources/${sourceId}/columns/${columnId}`,
        deleteSourceColumn: (sourceId: Id, columnId: Id) =>
            `/api/sources/${sourceId}/columns/${columnId}`,
        moveSourceColumn: (sourceId: Id, columnId: Id) =>
            `/api/sources/${sourceId}/columns/${columnId}/move`,
        getSourceFilters: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/filters`,
        getSourceFiltersMeta: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/filters/meta`,
        createSourceFilter: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/filters`,
        editSourceFilter: (sourceId: Id, filterId: Id) =>
            `/api/sources/${sourceId}/rules/filters/${filterId}`,
        deleteSourceFilter: (sourceId: Id, filterId: Id) =>
            `/api/sources/${sourceId}/rules/filters/${filterId}`,
        getSourceTransforms: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/transform`,
        getSourceTransformsMeta: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/transform/meta`,
        createSourceTransform: (sourceId: Id) =>
            `/api/sources/${sourceId}/rules/transform`,
        editSourceTransform: (sourceId: Id, transformId: Id) =>
            `/api/sources/${sourceId}/rules/transform/${transformId}`,
        deleteSourceTransform: (sourceId: Id, transformId: Id) =>
            `/api/sources/${sourceId}/rules/transform/${transformId}`,
        test: "/api/sources/test",
    },
    manuals: {
        get: "/api/manuals",
        create: "/api/manuals",
        getMeta: "/api/manuals/meta",
        approve: (id: Id) => `/api/manuals/${id}/approve`,
        delete: (id: Id) => `/api/manuals/${id}`,
    },
    workers: {
        get: "/api/workers",
        pause: (id: Id) => `/api/workers/${id}/pause`,
        unpause: (id: Id) => `/api/workers/${id}/unpause`,
    },
    historic: {
        get: "/api/historic",
        getById: (id: Id) => `/api/historic/${id}`,
        getMeta: "/api/historic/meta",
    },
    locks: {
        get: "/api/locks",
    },
    errors: {
        get: "/api/errors",
        getMeta: "/api/errors/meta",
        updateStatus: (id: Id) => `/api/errors/${id}/status`,
        updateRules: (id: Id) => `/api/errors/${id}/rules`,
    },
};

export default api;
