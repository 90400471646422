import React from "react";

const HistoricIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 8.438c0-.816.661-1.477 1.477-1.477H14S9 11 12.5 17c3 5.143 9.194 3.5 9.194 3.5v6.697a.37.37 0 0 1-.574.307L14.666 23.2a1.477 1.477 0 0 0-1.638 0l-6.454 4.303A.37.37 0 0 1 6 27.197V8.438Z"
            stroke="currentColor"
            strokeWidth={2}
        />
        <path
            d="M19 8.5v4.041c0 .458.082.696.433 1.044L21.5 15.5m5.424-2.653a7.847 7.847 0 1 1-15.694 0 7.847 7.847 0 0 1 15.694 0Z"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default HistoricIcon;
