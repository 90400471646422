import React, {createContext, FC, useContext, useState} from "react";
import CustomProvider from "rsuite/CustomProvider";
import ruRu from "rsuite/esm/locales/ru_RU";
import {ThemeProvider as StyledThemeProvider} from "styled-components";
import {APP_NAME} from "src/data/appName";
import EmptyCallback from "src/types/EmptyCallback";
import empty from "src/data/emptyFunction";
import {darkTheme, lightTheme} from "./Theme.styles";

interface IThemeContext {
    isDark: boolean;
    toggle: EmptyCallback;
}

const ThemeContext = createContext<IThemeContext>({
    isDark: true,
    toggle: empty,
});

const storageThemeName = `REACT_APP_DARK_THEME_${APP_NAME}`;
const storageThemeDarkValue = "true";
const storageThemeLightValue = "false";

export const ThemeProvider: FC = ({children}) => {
    const getSavedTheme = () => {
        const theme = localStorage.getItem(storageThemeName);
        if (!theme) return true;
        return theme === storageThemeDarkValue;
    };

    const [isDark, setIsDark] = useState(() => getSavedTheme());

    const toggle = () => {
        const nextTheme = !isDark;

        localStorage.setItem(
            storageThemeName,
            nextTheme ? storageThemeDarkValue : storageThemeLightValue,
        );
        setIsDark(nextTheme);
    };

    return (
        <ThemeContext.Provider value={{isDark, toggle}}>
            <StyledThemeProvider theme={isDark ? darkTheme : lightTheme}>
                <CustomProvider theme={isDark ? "dark" : "light"} locale={ruRu}>
                    {children}
                </CustomProvider>
            </StyledThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useThemeContext = () => {
    return useContext(ThemeContext);
};
