import React, {Suspense} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import routes from "src/routes";
import SuspenseLoading from "src/routes/SuspenseLoading";
import AdminLayout from "src/components/AdminLayout";
import AuthManager from "src/components/AuthManager";

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<SuspenseLoading />}>
                <AuthManager>
                    <AdminLayout>
                        <Routes>
                            {Object.entries(routes).map(
                                ([key, route]) =>
                                    route.page && (
                                        <Route
                                            path={route.url(":id")}
                                            key={key}
                                            element={route.page}
                                        />
                                    ),
                            )}
                        </Routes>
                    </AdminLayout>
                </AuthManager>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
