import React from "react";

const LogoIcon = () => (
    <svg viewBox="0 0 112 32" fill="none">
        <path
            d="M20.272 24.685c.276.537-.105 1.23-.73 1.23h-4.62a.812.812 0 01-.767-.457l-3.477-6.754H8.736v6.36c0 .47-.363.852-.822.852l-4.178-.007a.837.837 0 01-.824-.845V7.836c0-.467.37-.848.824-.848l4.178.002c.46 0 .822.381.822.846v5.91h1.436l3.2-6.296a.813.813 0 01.731-.464h4.608c.64 0 1.023.723.714 1.266l-3.783 7.518 4.63 8.915zM36.136 6.986H22.552a.837.837 0 00-.824.847v17.23c0 .472.367.853.824.853h13.584c.457 0 .824-.381.824-.852v-3.486a.837.837 0 00-.824-.845h-8.584v-2.029h5.403c.46 0 .824-.38.824-.847v-3.263a.835.835 0 00-.824-.848h-5.403v-1.802h8.584c.457 0 .824-.383.824-.852V7.836c-.002-.467-.367-.85-.824-.85zm18.296 4.958h-2.016v9.014h2.016v-9.014zm5.824-4.108v17.228a.84.84 0 01-.829.852h-7.011v2.3c0 .476-.363.854-.822.854h-4.178c-.457 0-.824-.378-.824-.854v-2.3c-2.265 0-4.753-.007-7.018-.007a.833.833 0 01-.82-.845l-.002-17.228c0-.467.37-.848.822-.848h7.018V4.683c0-.471.143-.852.6-.852h4.178c.46 0 1.046.379 1.046.852v2.303l7.011.002c.462 0 .829.381.829.848zm-13.664 4.108h-2.016v9.014h2.016v-9.014zm31.205-4.958h-3.765c-.217 0-.46.06-.582.25L67.872 16V7.836a.838.838 0 00-.829-.848h-4.166a.841.841 0 00-.829.848v17.228a.84.84 0 00.829.852h3.94c.31 0 .56-.188.719-.433l5.264-8.244v7.825c0 .47.372.852.822.852H77.8c.454 0 .824-.381.824-.852V7.836c-.002-.467-.37-.85-.827-.85zm12.251 4.958H86.24v3.83h3.808v-3.83zm5.824-4.115v12.054c0 .466-.37.845-.818.845l-8.814.005v4.337c0 .471-.37.852-.82.852l-4.18-.006a.835.835 0 01-.824-.846V7.831c0-.466.365-.847.824-.847l13.814.002c.448-.005.818.376.818.843zM98.784 25.037c0 .489.37.879.825.879h8.432c.455 0 .823-.39.823-.88v-4.304h-10.08v4.305zM108.751 3.831l-9.899.013c-.43 0-.763.4-.739.857l.677 14.454h10.061l.685-14.49a.804.804 0 00-.785-.834zm-1.072 4.612c0 .566-.099.705-.661.7h-6.37c-.484 0-.661-.09-.661-.578l-.14-2.28c0-.422.06-.646.619-.637h6.712c.519 0 .661.152.581.659l-.08 2.136z"
            fill="currentColor"
        />
    </svg>
);

export default LogoIcon;
