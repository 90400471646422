import styled from "styled-components";

export const AdminLayoutWrapper = styled.div`
    position: relative;

    display: flex;

    height: 100vh;
    overflow: hidden;

    background-color: ${(props) => props.theme.content};
    color: ${(props) => props.theme.color};
`;

export const AdminLayoutContainer = styled.div`
    width: 100%;

    height: 100vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;
`;

export const AdminLayoutContent = styled.div`
    position: relative;
    width: 100%;
    flex-grow: 1;

    overflow-y: auto;

    padding: 2.5rem 2.5rem;
`;
