import React from "react";

const MoonIcon = () => (
    <svg viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="prefix__a" fill="currentColor">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.692 11.944c.525-.737-.356-1.569-1.25-1.433a7.081 7.081 0 01-7.874-8.984C5.82.66 5.112-.32 4.312.103a8.093 8.093 0 1010.379 11.843z"
            />
        </mask>
        <path
            d="M13.14 8.534c-.25.038-.509.058-.773.058v4a9.13 9.13 0 001.375-.103l-.602-3.955zm-.773.058a5.081 5.081 0 01-5.081-5.08h-4a9.081 9.081 0 009.081 9.08v-4zm-5.081-5.08c0-.498.07-.975.202-1.425L3.648.969a9.084 9.084 0 00-.362 2.542h4zM2 7.258a6.092 6.092 0 013.249-5.39L3.378-1.666A10.092 10.092 0 00-2 7.259h4zm6.093 6.093A6.093 6.093 0 012 7.26h-4c0 5.574 4.519 10.093 10.093 10.093v-4zm4.97-2.567a6.083 6.083 0 01-4.97 2.567v4c3.398 0 6.403-1.681 8.229-4.248l-3.26-2.319zM7.487 2.087a3.342 3.342 0 00-.603-3.02 2.88 2.88 0 00-1.494-.977 2.77 2.77 0 00-2.013.244l1.87 3.535a1.232 1.232 0 01-.875.089 1.124 1.124 0 01-.588-.364.66.66 0 01-.138-.625l3.84 1.118zm6.254 10.402a.659.659 0 01-.6-.218 1.122 1.122 0 01-.282-.63c-.044-.346.06-.655.202-.856l3.26 2.319c.405-.57.596-1.26.506-1.965a2.883 2.883 0 00-.772-1.61 3.344 3.344 0 00-2.916-.995l.602 3.955z"
            fill="currentColor"
            mask="url(#prefix__a)"
        />
    </svg>
);

export default MoonIcon;
