import React from "react";
import useUserLogout from "src/api/queries/user/useUserLogout";
import Loading from "src/components/Loading";
import LogoutIcon from "src/assets/icons/LogoutIcon";
import {NavIcon} from "../NavBar/NavBar.styles";

const Logout = () => {
    const {isLoading, mutate} = useUserLogout();

    function logout() {
        mutate();
    }

    return (
        <>
            {isLoading && <Loading content="Выходим из аккаунта" />}
            <NavIcon onClick={logout}>
                <LogoutIcon />
            </NavIcon>
        </>
    );
};

export default Logout;
