import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const SourcesPage = lazy(() => import("src/pages/sources/SourcesPage"));
const CreateSourcePage = lazy(
    () => import("src/pages/sources/CreateSourcePage"),
);
const EditSourcePage = lazy(() => import("src/pages/sources/EditSourcePage"));

export interface ISourcesRoutes {
    sources: IRoute;
    createSource: IRoute;
    editSource: IRoute;
    viewSource: IRoute;
}

const url = `${ADMIN_PATH}/sources`;

const sourcesRoutes: ISourcesRoutes = {
    sources: {
        title: "Источники",
        url: () => url,
        page: <SourcesPage />,
    },
    createSource: {
        title: "Создание источника",
        url: () => `${url}/create`,
        page: <CreateSourcePage />,
    },
    viewSource: {
        title: "Просмотр источника",
        url: (param) => `${url}/view/${param}`,
        page: <EditSourcePage />,
    },
    editSource: {
        title: "Редактирование источника",
        url: (param) => `${url}/edit/${param}`,
        page: <EditSourcePage isEditSourcePage />,
    },
};

export default sourcesRoutes;
