import React, {lazy} from "react";
import {IRoutes} from "src/routes/IRoutes";
import {
    columnsRoutes,
    sourcesRoutes,
    manualsRoutes,
    workersRoutes,
    historicRoutes,
    errorsRoutes,
    locksRoutes,
} from "src/routes/entities";

const MainPage = lazy(() => import("src/pages/MainPage"));

const routes: IRoutes = {
    main: {
        url: () => `/`,
        page: <MainPage />,
    },
    ...columnsRoutes,
    ...sourcesRoutes,
    ...manualsRoutes,
    ...workersRoutes,
    ...historicRoutes,
    ...errorsRoutes,
    ...locksRoutes,
};

export default routes;
