export enum Queries {
    user = "user",
    columns = "columns",
    source = "source",
    sources = "sources",
    sourcesMeta = "sourcesMeta",
    sourceColumns = "sourceColumns",
    sourceColumnsMeta = "sourceColumnsMeta",
    sourceFilters = "sourceFilters",
    sourceFiltersMeta = "sourceFiltersMeta",
    sourceTransforms = "sourceTransforms",
    sourceTransformsMeta = "sourceTransformsMeta",
    manuals = "manuals",
    manualsMeta = "manualsMeta",
    workers = "workers",
    historic = "historic",
    historicById = "historicById",
    historicMeta = "historicMeta",
    errors = "errors",
    locks = "locks",
}
