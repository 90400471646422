import React, {FC, lazy} from "react";
import Loading from "src/components/Loading";
import useUser from "src/api/queries/user/useUser";

const AuthPage = lazy(() => import("src/pages/AuthPage"));

const AuthManager: FC = ({children}) => {
    const {data: user, isLoading} = useUser();

    if (isLoading) {
        return <Loading content="Проверяем авторизацию" />;
    }

    if (!user) {
        return <AuthPage />;
    }

    return <>{children}</>;
};

export default AuthManager;
