import React from "react";

const LogoutIcon = () => (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.883 13.845l1.074 1.073 4.293-4.293-4.293-4.293-1.074 1.073 2.461 2.461H7.59v1.518h10.755l-2.46 2.46z"
            fill="#d00000"
            fillOpacity={0.98}
        />
        <path
            d="M17.052 17.065a9.047 9.047 0 01-6.44 2.667 9.048 9.048 0 01-6.44-2.667 9.035 9.035 0 01-2.654-6.44c0-2.432.934-4.72 2.654-6.44a9.048 9.048 0 016.44-2.667A9.047 9.047 0 0117.4 4.554h1.932A10.61 10.61 0 0010.612 0C4.744 0 0 4.757 0 10.625S4.744 21.25 10.612 21.25c3.61 0 6.8-1.801 8.72-4.554H17.4a9.267 9.267 0 01-.348.369z"
            fill="#d00000"
            fillOpacity={0.98}
        />
    </svg>
);

export default LogoutIcon;
