import React, {lazy} from "react";
import {IRoute} from "src/routes/IRoute";
import {ADMIN_PATH} from "src/data/adminPath";

const ErrorsPage = lazy(() => import("src/pages/errors/ErrorsPage"));

export interface IErrorsRoutes {
    errors: IRoute;
}

const url = `${ADMIN_PATH}/errors`;

const errorsRoutes: IErrorsRoutes = {
    errors: {
        title: "История ошибок",
        url: () => url,
        page: <ErrorsPage />,
    },
};

export default errorsRoutes;
