import React from "react";

const ColumnsIcon = () => (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27 10v15.5a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14m6 6-6-6m6 6h-5a1 1 0 0 1-1-1V4"
            stroke="currentColor"
            strokeWidth={2}
        />
        <path
            d="m19 14 3.293 3.293a1 1 0 0 1 0 1.414L19 22M13 14l-3.293 3.293a1 1 0 0 0 0 1.414L13 22"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
        />
    </svg>
);

export default ColumnsIcon;
