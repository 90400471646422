import styled from "styled-components";
import {NavLink} from "react-router-dom";

export const SideBarItemWrapper = styled.li``;

export const SideBarLinkWrapper = styled(NavLink)`
    display: grid;
    grid-template-columns: 2rem 1fr;
    gap: 0.75rem;
    align-items: center;

    width: 100%;
    padding: 0.75rem 0.5rem 0.75rem 1.5rem;

    color: ${(props) => props.theme.sideBarItemColor};
    font-size: 1.125rem;
    text-decoration: none !important;

    &:hover,
    &:focus,
    &:active {
        color: ${(props) => props.theme.sideBarItemColor};
        background-color: var(--semi-transparent-green);
    }

    &.active {
        background-color: var(--main-color);
        color: ${(props) => props.theme.sideBarItemActiveColor};
    }
`;
